._2-ZbH {
	display: inline-block;
	height: 20px;
	position: relative;
	width: 40px;
	margin-bottom: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

._3QxTi {
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	transition: background-color .2s;
	border-radius: 34px;
}

._3maKS {
	width: 0;
	height: 0;
	overflow: hidden;
}

._ju7HT {
	bottom: 2px;
	height: 16px;
	left: 2px;
	position: absolute;
	width: 16px;
	border-radius: 50%;
	transform: translateX(0);
	outline: none;
}

._ju7HT._3YMB2 {
	transform: translateX(20px);
}


